/* @font-face {
  font-family: "Courier New";
  src: url(../public/cour.ttf);
} */

.App {
  text-align: center;
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}

.App-logo {
  /* height: 40vmin; */
  width: 100%;
  pointer-events: none;
  margin: 10px 0;
}

.App-link {
  color: #61dafb;
}

.table {
  margin: 20px 0;
  width: 100%;
  height: 18vh;
  font-size: calc(5px + 2vmin);
}

.wrapper {
  width: 88vmin;
  text-align: start;
}

.bnb-price {
  font-size: calc(20px + 2vmin);
  background-color: #202020;
  display: inline;
}
