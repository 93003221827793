body {
  margin: 0;
  font-family: "Courier New";

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: #ffffff;
  margin-bottom: 10px;
}

p {
  color: #ffffff;
  margin-bottom: 10px;
  padding: 0;
}
